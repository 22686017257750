import { useSubscription } from ".";
import CloseButton from "../components/atoms/CloseButton";

const UpgradationModal = () => {
  const { showUpgradeModal, canBeUpgraded , setShowUpgrademodal} = useSubscription();

  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;
  return (
    <div
      className={`modal ${canBeUpgraded && showUpgradeModal ? "active" : ""}`}
      // style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            zIndex: 10,
          }}
          className="cursor-pointer"
        >
          <CloseButton
            onClose={(e) => {
              e.stopPropagation();
              setShowUpgrademodal(false);
            }}
          />
        </div>

        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            <p className="modal-heading mb-2 mt-3" style={{ fontSize: "22px" }}>
              Upgrade Your Subscription
            </p>
          </div>

          {/* )} */}
          <p
            className={`modal-description mt-2`}
            style={{ textAlign: "center" }}
          >
            <>
                Subscribe now to regain full access to our premium database and
                marketing tools.
                <br />{" "}
                <p>
                  <br />
                  Don’t miss out - Upgrade your account today!
                </p>
              </>
          </p>
          <div className="d-flex w-100 mt-3">
            <button
              className="btn btn-outline-dark"
              onClick={() => setShowUpgrademodal(false)}
              style={{ flex: "1 0 45%" }}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary mt-0"
              onClick={() => window.open(upgrade_link, "_blank")}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradationModal;
