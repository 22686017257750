import { useEffect } from "react";

function withUnsavedChangesWarning(WrappedComponent) {
  return function (props) {
    const { dirty } = props; // Using Formik's dirty property

    console.log("dorty", dirty);

    // debugger;
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        // debugger;
        // if (dirty) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Some browsers need this
        return message;
      };
      // };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
}

export default withUnsavedChangesWarning;
