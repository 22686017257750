import { patchFormData } from "../../../../services";

export const editCompanyOverview = async (data, id, token) => {
  const dataToSend = {
    name: data.name,
    profile_photo: data.logo,
    cover_photo: data.cover,
    role_type: data.type,
    email: data.email,
    website: data.website,
    country_code: data.countryCode,
    founded_date: data.foundedDate,
    crm_id: data.crmId,
    mobile: data.contactNumber.toString(),
    status: data.status,
    overview: data.overview,
    internal_note: data.internal_note,
    aum_amount: typeof data.amount === "string" ? null : data.amount,
    aum_month_year: data.date,
    is_prelaunch: data.prelaunch,
    is_not_applicable: data.notApplicable,
    not_available : data.not_available,
    investor_type: data.investorType || null,
    profile_claimed: data.profileStatus,
    primealpha_update_date: data.primealpha_update_date,
    remove_profile_photo: data?.remove_profile_photo,
    remove_cover_photo: data?.remove_cover_photo,
    user_role: "Customer",
  };

  try {
    const response = await patchFormData({
      endpoint: "Company/editCompanyDetails",
      data: dataToSend,
      params: { company_id: id },
      token: token,
    });

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error; // rethrow the error to handle it in the calling code
  }
};
