import { useSelector } from "react-redux";
import { useCreditCount } from "../../../../../../contexts/CreditCountContext";
import { useSubscription } from "../../../../../../SubscriptionContext";
import UserProfileCardRoad from "../../../../../molecules/UserProfileCard/userCardRoadMap";
import style from "./index.module.scss";

const InvestorPeople = ({ company_id, data,type }) => {
  // console.log("company_id", company_id);

  const {updateCreditInfo} = useCreditCount();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const handleClick = (e,people_id) => {
    if (type === "roadshow" && isSubscriptionExpired) {
      handleUserClick(e);
    } else {
      if(userInfo?.walkin_trial) {
        updateCreditInfo({type : 'people',id : people_id})
      } else {
        window.open(`/dashboard/people/${people_id}`, "_blank");
      }

    }
  };

  return (
    <div className={style.peopleWrap}>
      {data.length > 0 &&
        data?.map((item) => {
          return (
            <div>
              <div className={style.company_team}>
                <UserProfileCardRoad
                  key={item.people_id}
                  profileName={item.first_name}
                  profilePosition={item.title}
                  onClick={(e) => handleClick(e,item?.people_id)}
                  profilePicture={item.profile_photo}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default InvestorPeople;
