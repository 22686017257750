import FormHeading from "../../atoms/FormHeading";
import WalkinForm from "../../organisms/forms/authForms/WalkinForm";

const WalkInAccess = () => {
  return (
    <>
      <FormHeading
        title="Start Your Free Trial"
        description="Enter your email to receive a One-Time Password."
      />
      <WalkinForm/>
    </>
  );
};

export default WalkInAccess;
