// Define the Button component
import { useSubscription } from "../../SubscriptionContext";

const FixedPositionButton = ({
  onClick,
  disabled = false,
  children,
  label,
  position = { top: "20px", right: "20px" },
}) => {
  const { top } = useSubscription();

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        position: "fixed", // Fixed position
        ...position, // Custom position props
        top: `${(parseInt(top) ? parseInt(top) : 0) + 90}px`,
        padding: "10px 20px",
        backgroundColor: disabled ? "#ccc" : "#007bff", // Color changes if disabled
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: disabled ? "not-allowed" : "pointer",
        boxShadow: "0px 4px 10px rgba(61, 155, 233, 0.2)", // Optional shadow for style
        zIndex: 199, // Ensure it stays on top
        fontSize: "14px",
      }}
      className="btn btn-primary"
      type="button"
    >
      {label || "Save Changes"}
    </button>
  );
};

export default FixedPositionButton;
