import { useState } from 'react';
import './styles.scss';

const CreditNotificationCard = () => {
    const STORAGE_KEY = "credit_notification_seen";

    // Use state to control visibility of the notification
    const [isVisible, setIsVisible] = useState(
        localStorage.getItem(STORAGE_KEY) !== "false"
    );
    
    // Hide notification & update localStorage
    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem(STORAGE_KEY, "false");
    };
    
    // Don't render if hidden
    if (!isVisible) return null;

    return (
        <>
            <div id="notificationCard" className="notification-card shadow">
                <div className="card-body d-flex align-items-start">
                
                    <div className="message flex-grow-1">
                        <p className="mb-0 fw-medium">
                        Viewing any profile will consume 1 credit.
                        </p>
                    </div>
                    <button className="btn-close" onClick={handleClose}>
           
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreditNotificationCard;
