import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decryptUUID, encryptUUID } from "../../encrypt";
import { useAuthenticated } from "../../hooks/useAuthenticated";
import { getData, patchData } from "../../services";

const CreditCountContext = createContext();

// Custom hook to use the SubscriptionContext
export const useCreditCount = () => useContext(CreditCountContext);

const getStoredCreditDetails = () => {
  const encodedData = sessionStorage.getItem("creditDetails");
  if (encodedData) {
    try {
      return JSON.parse(atob(encodedData)); // Decode and parse
    } catch (error) {
      console.error("Error decoding credit details:", error);
    }
  }
  return {
    people_credit_used: null,
    company_credit_used: null,
    total_people_credit: null,
    total_company_credit: null,
  };
};

const saveCreditDetails = (data) => {
  try {
    const encodedData = btoa(JSON.stringify(data)); // Encode to Base64
    sessionStorage.setItem("creditDetails", encodedData);
  } catch (error) {
    console.error("Error encoding credit details:", error);
  }
};

// SubscriptionProvider component to wrap around the App
export const CreditCountProvider = ({ children }) => {
  const [cookies] = useCookies(["cuid", "t"]);
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const isAuthenticated = useAuthenticated();

  const [isShow, setIsShow] = useState(false);
  const [type, setType] = useState("People");

  const [creditDetails, setCreditDetailsState] = useState(getStoredCreditDetails());

  // Function to update credit details both in state and sessionStorage
  const setCreditDetails = (newDetails) => {
    setCreditDetailsState(newDetails);
    saveCreditDetails(newDetails); // Save in sessionStorage
  };

  const handleNavigate = ({ type, id, navigationType, modalFunction }) => {
    if (type === "people") {
      if (navigationType === "window") {
        window.open(`/dashboard/people/${id}`, "_blank");
      } else {
        navigate(`/dashboard/people/${id}`);
      }
    } else if (type === "company") {
      if (navigationType === "window") {
        window.open(`/dashboard/company/${id}`, "_blank"); // Navigate to company dashboard
      } else if (navigationType === "modal") {
        modalFunction(true);
      } else {
        navigate(`/dashboard/company/${id}`);
      }
    }
  };

  const updateCreditInfo = async ({
    type,
    id,
    navigationType = "window",
    modalFunction,
  }) => {
    const encryptedID = encryptUUID(id);

    // Get the viewed array from localStorage
    let viewed =
      JSON.parse(localStorage.getItem(`${cookies.cuid}-viewed`)) || [];

    // Check if the current ID has already been viewed
    const isAlreadyViewed = viewed.some((storedEncryptedID) => {
      try {
        const decryptedID = decryptUUID(storedEncryptedID);
        return decryptedID === id; // Check if decrypted ID matches the current ID
      } catch (error) {
        console.error("Decryption failed for stored ID:", storedEncryptedID);
        return false;
      }
    });

    if (!isAlreadyViewed) {
      // Add the encrypted ID to the viewed array

      // Update credit details only if the ID is not already viewed
      const updatedDetails = { ...creditDetails };

      const creditsLeft =
        type === "people"
          ? creditDetails?.total_people_credit -
            creditDetails?.people_credit_used
          : creditDetails?.total_company_credit -
            creditDetails?.company_credit_used;

      // If credits are exhausted, show a modal
      if (creditsLeft <= 0 || isNaN(creditsLeft)) {
        setIsShow(true); // You should implement this modal logic
        setType(type);
      } else {
        // If credits are available, navigate based on the type
        if (type === "people") {
          updatedDetails.people_credit_used = (updatedDetails.people_credit_used ?? 0) + 1;
        }
        if (type === "company") {
          updatedDetails.company_credit_used = (updatedDetails.company_credit_used ?? 0) + 1;
        }
  
        setCreditDetails(updatedDetails);

        viewed.push(encryptedID);
        localStorage.setItem(`${cookies.cuid}-viewed`, JSON.stringify(viewed));

        handleNavigate({ type, id, navigationType, modalFunction });

        try {
          // Call the API to update profile count
          const res = await patchData({
            endpoint: "Trial/updateProfileCount",
            params: {
              user_id: cookies.cuid,
              type: type,
              view_id : id
            },
          });

          // Check if credits are exhausted based on the response
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      }
    } else {
      handleNavigate({ type, id, navigationType, modalFunction });
    }
  };

  const getCreditInfo = async () => {
    const trial_data_string = localStorage.getItem("trial_info");
    let trial_data;
    
    if (trial_data_string !== null) {
      trial_data = JSON.parse(trial_data_string);
    }
    if (cookies.cuid && (userInfo.walkin_trial || trial_data?.walkin_trial)) {
      const res = await getData({
        endpoint: "Trial/getUserCredits",
        params: { people_id: cookies.cuid },
        token: cookies.t,
      });

      setCreditDetails(res);
    }
  };

  useEffect(() => {
    if(isAuthenticated){
      getCreditInfo();
    }
  }, [ isAuthenticated,userInfo]);

  // Function to handle user clicks
  const handleAccessContextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShow(true);
  };

  return (
    <CreditCountContext.Provider
      value={{
        handleAccessContextClick,
        setIsShow,
        isShow,
        type,
        setType,
        creditDetails,
        setCreditDetails,
        getCreditInfo,
        updateCreditInfo,
      }}
    >
      {children}
    </CreditCountContext.Provider>
  );
};
