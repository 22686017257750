import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import TabStrip from "../../../../molecules/TabStrip";
import CompanyTeam from "./CompanyTeam";
import InsightsNews from "./InsightsNews";
import InvestmentPreference from "./InvestmentPreference";
import Overview from "./Overview";
import PreferencesNotes from "./PreferencesNotes";
import CompanyProduct from "./Product";

const Data = ({ data ,isAnotherCompany}: any) => {
  const location = useLocation();
  const { navigationTab } = location?.state || {};

  useEffect(() => {
    if (navigationTab) {
      setActiveTab(navigationTab);
    }
  }, [navigationTab]);

  const [activeTab, setActiveTab] = useState("Overview");

  const typeValue = data?.company_overview?.role;

  const dataArray = useMemo(() => {
    const typeValue = data?.company_overview?.role;
    let dataArray = [
      {
        id: 0,
        label: "Overview",
        view: (
          <Overview
            data={data}
            changeTab={(value: any) => setActiveTab(value)}
          />
        ),
      },
    ];

    if (
      (typeValue === "Manager/Sponsor" ||
        typeValue === "Investor/Manager/Sponsor") &&
      data?.product.length > 0
    ) {
      dataArray.push({
        id: 1,
        label: "Products",
        view: <CompanyProduct data={data} />,
      });
    }

    if (
      (typeValue === "Investor" || typeValue === "Investor/Manager/Sponsor") &&
      (data?.investment_preference.length > 0 ||
        data?.people_investment_preference?.length)
    ) {
      dataArray.push({
        id: 2,
        label: "Investment Preference",
        view: <InvestmentPreference data={data} />,
      });
    }

    if (
      (typeValue === "Investor" || typeValue === "Investor/Manager/Sponsor") &&
      (data?.preference_notes?.length > 0 || data?.people_preference_notes?.length)
    ) {
      dataArray.push({
        id: 2,
        label: "Preference Notes",
        view: <PreferencesNotes data={data?.preference_notes} people_note={data?.people_preference_notes}/>,
      });
    }

    if (data?.insights_news?.length > 0) {
      dataArray.push({
        id: 3,
        label: "Insights & News",
        view: <InsightsNews data={data} />,
      });
    }
    if (
      data?.teams &&
      Object.values(data?.teams).some((team: any) => team?.length > 0)
    ) {
      dataArray.push({
        id: 2,
        label: "Team",
        view: <CompanyTeam data={data} isAnotherCompany={isAnotherCompany}/>,
      });
    }

    return dataArray;
  }, [data?.company_overview]);

  const _getComponent = (tabs: any) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event: any, value: any) => {
    setActiveTab(value);
  };

  return (
    <>
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
      />
      <div className="main-box-company">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ py: "1.56rem" }}>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </>
  );
};

export default Data;
