import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthenticated } from "../hooks/useAuthenticated";

// Create a context for subscription status
const SubscriptionContext = createContext();

// Custom hook to use the SubscriptionContext
export const useSubscription = () => useContext(SubscriptionContext);

// SubscriptionProvider component to wrap around the App
export const SubscriptionProvider = ({ children }) => {
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(false);
  const [canBeUpgraded, setCanBeUpgraded] = useState(false);
  const [showUpgradeModal , setShowUpgrademodal] = useState(false)
  const [isShow, setIsShow] = useState(false);
  const [isTrialActivationModalVisible, setIsTrialActivationModalVisible] =
    useState(false);
  const [trialData, setTrialData] = useState(null);
  const [top, setTop] = useState("0px");

  const isAuthenticated = useAuthenticated();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  // Simulate checking subscription status from an API or server
  useEffect(() => {
    const checkSubscription = () => {
      const trial_data_string = localStorage.getItem("trial_info");
      let trial_data;

      if (trial_data_string !== null) {
        trial_data = JSON.parse(trial_data_string);
      }

      // Validate userInfo
      const isUserInfoValid =
        userInfo &&
        typeof userInfo === "object" &&
        (userInfo.hasOwnProperty("subscription_enable") ||
          userInfo.hasOwnProperty("is_trial") ||
          userInfo.hasOwnProperty("walkin_trial")) &&
        (userInfo.hasOwnProperty("subs_end_date") ||
          userInfo.hasOwnProperty("trial_end") ||
          userInfo.hasOwnProperty("walkin_trial_end"));

      // Define the subscription/trial check logic
      const isSubscriptionOrTrialExpired = () => {
        if (isUserInfoValid) {
          // Prioritize userInfo for subscription, trial, and walkin_trial checks
          if (userInfo.is_trial) {
            return moment(userInfo.trial_end)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment());
          }

          if (userInfo.walkin_trial) {
            return moment(userInfo.walkin_trial_end)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment());
          }

          return (
            userInfo.subscription_enable === false ||
            moment(userInfo.subs_end_date)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment())
          );
        } else {
          // Fall back to trial_data if userInfo is not valid
          if (trial_data?.is_trial) {
            return moment(trial_data?.trial_end)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment());
          }

          if (trial_data?.walkin_trial) {
            return moment(trial_data?.walkin_trial_end)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment());
          }

          return (
            !trial_data?.subscription_enable ||
            moment(trial_data?.subs_end_date)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment())
          );
        }
      };

      const endDate = isUserInfoValid
        ? userInfo?.trial_end ||
          userInfo?.subs_end_date ||
          userInfo?.walkin_trial_end
        : trial_data?.trial_end ||
          trial_data?.subs_end_date ||
          trial_data?.walkin_trial_end;

      const walkInTrial =
        userInfo?.walkin_trial ?? trial_data?.walkin_trial ?? null;

      if (isSubscriptionOrTrialExpired() && !endDate) {
        setSubscriptionExpired(true);
        setIsShow(true);
      } else if (isSubscriptionOrTrialExpired()) {
        setSubscriptionExpired(true);
        setIsShow(false);
      } else {
        setSubscriptionExpired(false);
        setIsShow(false);
      }

      if (walkInTrial) {
        setCanBeUpgraded(true);
      }
    };

    if (isAuthenticated) {
      checkSubscription();
    }

    // both isAuthenticated ND userInfo are required in the dependency to get the updated permissions
  }, [isAuthenticated, userInfo]);

  // Function to handle user clicks
  const handleUserClick = (e, type = "normal") => {
    debugger
    console.log('type',type)
    if (type === "normal" && isSubscriptionExpired) {
      console.log('1')
      e.preventDefault();
      e.stopPropagation();
      setIsShow(true);
    } else if (type == "upgrade" && canBeUpgraded) {
      console.log('2')
      e.preventDefault();
      e.stopPropagation();
      setShowUpgrademodal(true);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscriptionExpired,
        handleUserClick,
        setIsShow,
        isShow,
        top,
        setTop,
        isTrialActivationModalVisible,
        setIsTrialActivationModalVisible,
        trialData,
        setTrialData,
        canBeUpgraded,
        showUpgradeModal,
        setShowUpgrademodal
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
