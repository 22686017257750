import Lottie from "react-lottie";
import * as animationData from "../../../assets/lottieFile/confirmationLottie.json";

const ConfirmationLottie = () => {
  // Lottie animation options
  const defaultOptions = {
    loop: true, // Set to false if you want it to play only once
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex justify-center items-center">
      <Lottie options={defaultOptions} height={120} width={120} />
    </div>
  );
};

export default ConfirmationLottie;
