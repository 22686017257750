import { useEffect } from "react";
import { useSubscription } from ".";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import { useRef } from "react";
import { useSelector } from "react-redux";
import CloseButton from "../components/atoms/CloseButton";
import ConfirmationLottie from "../components/atoms/ConfirmationLottie";


const TrialActivationModal = () => {
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;
  const modalRef = useRef(null);

  const { isTrialActivationModalVisible, setIsTrialActivationModalVisible ,trialData} =
    useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsTrialActivationModalVisible(false);
      }
    };

    if (isTrialActivationModalVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTrialActivationModalVisible]);



  return (
    <div
      className={`modal ${isTrialActivationModalVisible ? "active" : ""}`}
      // style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content" ref={modalRef}>
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            zIndex: 10,
          }}
          className="cursor-pointer"
        >
          <CloseButton
            onClose={(e) => {
              e.stopPropagation();
              setIsTrialActivationModalVisible(false);
            }}
          />
        </div>

        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
          <ConfirmationLottie />
            {/* <p className="modal-heading mb-0 mt-2">
   

              {!endDate
                ? "Activate Your Subscription"
                : localMode
                ? "Upgrade Your Subscription"
                : "Renew Your Subscription"}
            </p> */}

            <p className="modal-heading mb-0 my-2">
            Free Access Activated Successfully
            </p>
          </div>

          <p
            className={`modal-description mt-2`}
            style={{ textAlign: "center" }}
          >
            Welcome. Explore the platform with limited access to selected features to see what we offer.
            <br />
            <br/>
            Explore our directory. You have been granted <strong>{trialData?.total_people_credit || 0} People Credits</strong> & <strong>{trialData?.total_company_credit || 0} Company Credits.</strong>
            <br/>
            <br />
            Enjoy!
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrialActivationModal;
