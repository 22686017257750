import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSubscription } from "../../../SubscriptionContext";
import { getDaysLeftUntilTrialEnd } from "../../organisms/forms/authForms/commonLogic";
import CloseBtnIcon from "../Modal/CloseBtnIcon";
import "./styles.scss";

const TrialBanner = () => {
  const [type, setType] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const { setTop } = useSubscription();
  // const [, setStoredValue] = useLocalStorage("refnavtop");
  const trialData = JSON.parse(localStorage.getItem("trial_info"));
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const upgradeLink = process.env.REACT_APP_PAYMENT_LINK;

  // Determine local mode using priority
  const localMode =
    userInfo?.is_last_subs_mode_trial ||
    (!userInfo && trialData?.is_last_subs_mode_trial);

  useEffect(() => {
    const isUserInfoValid =
      userInfo &&
      typeof userInfo === "object" &&
      userInfo.hasOwnProperty("subscription_enable") &&
      userInfo.hasOwnProperty("subs_end_date");
    // Determine the active data (userInfo > trialData)

    // console.log("isUserInfoValid", isUserInfoValid);
    const activeData = isUserInfoValid ? userInfo : trialData;

    if (activeData) {
      const { is_trial, subscription_enable, days_left, subs_end_date } =
        activeData;

      // console.log("subs_end_date", subs_end_date);

      let found_days_left =
        days_left ||
        (subs_end_date ? getDaysLeftUntilTrialEnd(subs_end_date) : null);

      if (
        subs_end_date &&
        (is_trial || (subscription_enable && found_days_left < 15))
      ) {
        if (!bannerDismissed) {
          setTop("47px");
          setType("going-to-end");
          setBannerVisible(true);
        }
      } else if (subs_end_date && !subscription_enable) {
        if (!bannerDismissed) {
          setTop("47px");
          setType("ended");
          setBannerVisible(true);
        }
      } else {
        setTop("0px");
        setBannerVisible(false);
      }
    }

    // both trialData Nd userInfo are required in the dependency to get the updated permissions
  }, [trialData, userInfo]);

  return (
    <>
      {!bannerDismissed && bannerVisible && (
        <div className="trial-banner">
          <div></div>

          {type === "going-to-end" && (
            <div>
              Your{" "}
              {userInfo?.is_trial || trialData?.is_trial
                ? "Trial"
                : "Subscription"}{" "}
              ends on{" "}
              {moment(
                userInfo?.subs_end_date || trialData?.subs_end_date
              ).format("MMM D, YYYY")}
              . &nbsp;
              <a
                style={{ textDecoration: "underline" }}
                href={upgradeLink}
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              &nbsp;to{" "}
              {userInfo?.is_trial || trialData?.is_trial ? "upgrade" : "renew"}{" "}
              now.
            </div>
          )}
          {type === "ended" && (
            <div>
              {localMode
                ? "Your free trial has ended."
                : "Your premium subscription has ended. "}
              {"  "}
              <a
                style={{ textDecoration: "underline" }}
                href={upgradeLink}
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              &nbsp;to {localMode ? "upgrade" : "renew"} now.
            </div>
          )}

          <div
            className="trial-icon-div cursor-pointer"
            onClick={() => {
              setBannerDismissed(true);
              setTop("0px");
            }}
          >
            <CloseBtnIcon />
          </div>
        </div>
      )}
    </>
  );
};

export default TrialBanner;
