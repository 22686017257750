import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

const AccessContext = createContext();

// Custom hook to use the SubscriptionContext
export const useAccess= () => useContext(AccessContext);

// SubscriptionProvider component to wrap around the App
export const AccessProvider = ({ children }) => {
  const [isShow, setIsShow] = useState(false);
  const [type, setType] = useState('Investor')

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  // Function to handle user clicks
  const handleAccessContextClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsShow(true);
  };

  return (
    <AccessContext.Provider
      value={{
        handleAccessContextClick,
        setIsShow,
        isShow,
        type, 
        setType
      }}
    >
      {children}
    </AccessContext.Provider>
  );
};
