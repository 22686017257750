import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useCreditCount } from ".";
import "../../components/molecules/Modal/LogoutModal/logout-modal.scss";

const CreditCountModal = () => {
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  const { isShow, setIsShow, type, setType } = useCreditCount();

  // Ref for the modal content
  const modalContentRef = useRef();

  useEffect(() => {
    // Close the modal when clicking outside of the modal content
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        setIsShow(false);
      }
    };

    if (isShow) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShow, setIsShow]);

  return (
    <div
      className={`modal ${isShow ? "active" : ""}`}
      style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content" ref={modalContentRef}>
        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2 mt-3">
            {/* <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            /> */}
            <p className="modal-heading mb-2 mt-2" style={{fontSize:'22px'}}>Credit Information</p>
          </div>

          <p
            className={`modal-description mt-2 mb-1 px-1`}
            style={{ textAlign: "center" }}
          >
            You have exhausted all your available {type} credits.
            <br />

            To continue using PrimeAlpha without interruptions, subscribe now to
            unlock unlimited access!
            <br />
            <br />
     
            <div className="d-flex w-100 mt-1 justify-content-center px-2">
              <button
                className="btn btn-outline-dark"
                onClick={() => setIsShow(false)}
                style={{ flex: "1 0 45%" }}
              >
                Cancel
              </button>

              <button
                className="btn btn-primary"
                onClick={() => window.open(upgrade_link, "_blank")}
                style={{ flex: "1 0 45%", marginLeft: "20px" }}
              >
                Subscribe Now
              </button>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditCountModal;
