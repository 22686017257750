import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../atoms/Dropdown";
import Heading from "../../../atoms/Heading";
import Loader from "../../../atoms/Loader";
import Search from "../../../atoms/Search";
import BlogFilters from "../../../organisms/blogFilters/index";
import BlogTemplate from "../../../templates/BlogTemplate";
import Helper from "./Helper";
import ShimmerResources from "./Shimmer/shimmer";
import "./resources.scss";

const Resources = () => {
  const [cookies] = useCookies(["t", "role"]);
  const dispatch = useDispatch();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<any>("");
  const [categories, setCategories] = useState<any>(); // Use string[] for categories
  const [resourceData, setResourceData] = useState({
    data: [],
    total_record: 0,
  });
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [pageLimit, setPageLimit] = useState(9);

  const { filterData, companyList } = Helper();
  const loadingDataValue = useSelector(loaderData);

  const getResourceData = useCallback(async () => {
    try {
      setDataLoading(true);
      // Add a setTimeout of 2000ms (2 seconds)
      const res = await getData({
        endpoint: "Blog/customer/getAllResourcesBlogs",
        params: {
          search_query: searchedValue,
          categories: JSON.stringify(categories),
          company_id: selectedCompany,
          page: 1,
          page_limit: pageLimit,
        },
        token: cookies.t,
      });

      if (res) {
        setTimeout(() => {
          setDataLoading(false);
        }, 50);

        setResourceData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, categories, cookies.t, selectedCompany, pageLimit]);

  useEffect(() => {
    getResourceData();
  }, [getResourceData]);

  const handleSearchEmit = (searchValue: string) => {
    setIsSearched(true);
    setSearchedValue(searchValue);
  };

  const handleFilterSelect = (option: string) => {
    setIsSearched(true);
    if (option === "All") {
      setCategories(null);
    } else {
      setCategories([option]); // Use spread operator to correctly update state
    }
  };

  const loadMore = () => {
    setPageLimit((prev) => prev + 9);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="resource-container">
        <Heading title="Resources" type="main">
          <Search onSearchEmit={handleSearchEmit} placeholder="Search" />
        </Heading>

        <div className="d-flex align-items-center justify-content-between All-company-container">
          <BlogFilters
            data={filterData}
            onFilterSelect={handleFilterSelect} // Use the updated function
          />

          <SelectDropdown
            data={companyList}
            placeholder="Select Company"
            selectedValue="All Company"
            onChange={(value) => {
              setIsSearched(true);
              if (value === "All Company") {
                setSelectedCompany(null);
              } else {
                setSelectedCompany(value);
              }
            }}
            type="filter"
          />
        </div>
        {dataLoading ? (
          <ShimmerResources />
        ) : (
          <>
            <BlogTemplate data={resourceData?.data} isSearched={isSearched} />

{userInfo?.resource && 
            <div className="load-more">
              {resourceData.total_record > pageLimit && (
                <button
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => loadMore()}
                >
                  Load More
                </button>
              )}
            </div>
}
          </>
        )}
      </div>
    </>
  );
};

export default Resources;
