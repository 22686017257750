// App.js
import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import "./assets/scss/style.scss";
import GlobaLoader from "./components/atoms/GlobalLoader";
import { AccessProvider } from "./contexts/AccessContext";
import { CreditCountProvider } from "./contexts/CreditCountContext";
import CreditCountModal from "./contexts/CreditCountContext/CreditCountModal";
import { FieldsModifiedProvider } from "./contexts/FeildsModifiedContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import Loader from "./contexts/LoadingContext/Loader";
import routes from "./routes";
import ScrollToTop from "./SrollToTop";
import { setPeopleData } from "./store/slices/peopleSlice";
import { SubscriptionProvider } from "./SubscriptionContext";
import TrialActivationModal from "./SubscriptionContext/TrialActivationModal";
import UpgradationModal from "./SubscriptionContext/UpgradationModal";

function App() {
  const content = useRoutes(routes);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, , removeCookies] = useCookies(["t", "un", "email", "cuid"]);
  const cookieNames = ["t", "un", "email", "cuid", "cid", "role"];

  const handleLogout = () => {
    setPeopleData({});
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
    dispatch(setPeopleData({ peopleData: { response: null } }));
    navigate("/", { replace: true });
    localStorage.removeItem("trial_info");
    localStorage.removeItem("refnavtop");
  };

  useEffect(() => {
    if (userInfo.response === 404) {
      handleLogout();
    }
    if (!userInfo?.filter_access) {
      document.querySelector("#filter-parent")?.classList.add("filters-not-allowed");
    }
    document.querySelectorAll("#filter-parent").forEach((el) => {
      (el).style.pointerEvents = "none";
    });
  }, [userInfo]);



  const loadingBarRef = useRef(null);
  const loadingData = useSelector((state) => state.topLoader.loading);

  useEffect(() => {
    if (loadingData) {
      loadingBarRef.current.continuousStart();
      setTimeout(() => {
        loadingBarRef.current.complete();
      }, 500);
    }
  }, [loadingData]);

  return (
    // <BotDetectionProvider>
    //   <BotAlert />
    <SubscriptionProvider>
      <CreditCountProvider>
      <AccessProvider>
        <LoadingProvider>
          <FieldsModifiedProvider>
            <GlobaLoader loadingBarRef={loadingBarRef} />
            <CreditCountModal/>
            <UpgradationModal/>
            <TrialActivationModal/>
            <ScrollToTop />
            {content}
            <Toaster />
            <Loader />
            {/* <ModifiedFields /> */}
          </FieldsModifiedProvider>
        </LoadingProvider>
      </AccessProvider>
      </CreditCountProvider>
    </SubscriptionProvider>

    // </BotDetectionProvider>
  );
}

export default App;
