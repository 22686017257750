import SearchBar from "../../../pages/dashboard/Investors/Icons/SearchBar";
import "./QuickSearch.scss";
const QuickSearch = ({ quickSearchData, type, handleQuickSearch }) => {
  return (
    <div className="Quick-search-Container">
      <h1>Quick Search</h1>
      {type === "investor" && (
        <div className="Quick-search-Items">
          {quickSearchData?.map((item, index) => {
            return (
              <button
                key={index}
                onClick={(e) => {
                  handleQuickSearch(e,item);
                }}
              >
                <SearchBar />
                {item?.label}
              </button>
            );
          })}
        </div>
      )}
      {type === "productType" && (
        <div className="Quick-search-Items">
          {quickSearchData?.map((item, index) => {
            return (
              <button
                key={index}
                onClick={(e) => {
                  handleQuickSearch(e,item);
                }}
              >
                <SearchBar />
                {item?.label}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuickSearch;
