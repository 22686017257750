import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Keep this key private and secure

// Encrypt UUID
export const encryptUUID = (uuid) => {
  const ciphertext = CryptoJS.AES.encrypt(uuid, SECRET_KEY).toString();
  return ciphertext;
};

// Decrypt UUID
export const decryptUUID = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  const originalUUID = bytes.toString(CryptoJS.enc.Utf8);
  return originalUUID;
};