import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { TypeData } from "../../../../helpers/staticData";
import InputLayout from "../../../atoms/InputLayout";
import { MonthYearSelector } from "../../../molecules/MonthYearSelector";

import { Field, Formik } from "formik";
import * as yup from "yup";
import Error from "../../../atoms/Error";

import { countryCodeData } from "../../../../helpers/countryCodes";
import SelectDropdown from "../../../atoms/Dropdown";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";

import { replaceSpacesInImageUrl } from "../../../../helpers/replaceSpaceInImageUrl";
import CheckboxWrapper from "../../../atoms/CheckBox";

import { getData } from "../../../../services";
import TabData from "./TabData";
import "./styles.scss";

import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confimationStyles } from "../../../../assets/styles/toast";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { setLoading } from "../../../../store/slices/loaderSlice";
import FixedPositionButton from "../../../atoms/FixedPositionButton";
import QuillEditor from "../../../molecules/QuillEditor/QuillEditor";
import EditBlock from "../../../templates/BlockEdit";
import { editCompanyOverview } from "./editCompanyHelper";

const EditCompany = () => {
  const [cookies] = useCookies(["cid", "t"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [maintainedData, setMaintainedData] = useState();
  const [investorData, setInvestorData] = useState();
  const [CompanyeditActive, setCompanyEditActive] = useState(false);
  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (screenWidth <= 900) {
      setCompanyEditActive(false);
    } else {
      setCompanyEditActive(true);
    }
  }, [screenWidth]);

  const fetchCompanyData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Company/getCompanyDetails",
      params: { company_id: cookies.cid },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setMaintainedData(res[0]);
    }
  };

  const fetchInvestorData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Taxonomy/getInvestorTypeList",
      params: {},
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setInvestorData(res);
  };

  useEffect(() => {
    fetchCompanyData();
    fetchInvestorData();
  }, []);

  const ImageSelectorStyle = {
    borderRadius: "10px",
    border: "1px solid #EFEFEF",
  };

  const initialValues = {
    logo: maintainedData?.profile_photo || null,
    cover: maintainedData?.cover_photo || null,
    name: maintainedData?.name || "", // This will be "twst" from the API data
    type: maintainedData?.company_type_id || null,
    linkedIn: "",
    email: maintainedData?.email || "",
    website: maintainedData?.website || "", // "https://test1@gmail.com" from the API
    countryCode: maintainedData?.country_code || "", // "91" from the API
    contactNumber: maintainedData?.mobile || "", // Assuming 'mobile' from API for contactNumber
    status: maintainedData?.status || "",
    overview: maintainedData?.overview || "",
    prelaunch: maintainedData?.prelaunch || false,
    notApplicable: maintainedData?.not_applicable || false,
    not_available : maintainedData?.not_available || false,
    amount: maintainedData?.aum_amount || null,
    date: maintainedData?.aum_month_year || null,
    investorType: maintainedData?.investor_type || [], // Assuming the first element of the array for investorType
    profileStatus: maintainedData?.profile_claimed || false, // Assuming 'status' from API for profileStatus
    foundedDate: maintainedData?.founded_date || "",
    crmId: maintainedData?.crm_id || "",
    internal_note: maintainedData?.internal_notes || null,
    primealpha_update_date: maintainedData?.primealpha_update_date || null,
    tag: maintainedData?.tag,
    user_role: "Customer",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    type: yup.mixed().required("Type is required"),
    email: yup.string().email("Invalid email"),
    // .required("Email is required"),
    website: yup
      .string()
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(?:\/[^\s]*)?$/,
        "Invalid URL"
      ),
    // .required("Website is required"),
    // countryCode: yup.string(),
    // // .required("Country Code is required"),
    // contactNumber: yup.number(),
    // .required("Contact Number is required"),
    countryCode: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { countryCode, contactNumber } = this.parent;
          if (
            (countryCode && !contactNumber) ||
            (!countryCode && contactNumber)
          ) {
            return false;
          }
          return true;
        }
      ),
    contactNumber: yup
      .number()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { countryCode, contactNumber } = this.parent;
          if (
            (countryCode && !contactNumber) ||
            (!countryCode && contactNumber)
          ) {
            return false;
          }
          return true;
        }
      ),
    prelaunch: yup.boolean(),
    notApplicable: yup.boolean(),
    amount: yup.number().when("notApplicable", {
      is: false,
      then: () =>
        yup.number().when("prelaunch", {
          is: false, // or true, based on your logic
          // then: () => yup.number().required("AUM amount is required"),
          then  : () => yup.number().when("not_available", {
            is: false, // or true, based on your logic
            then: () => yup.number().required("AUM amount is required"),
            otherwise: () => yup.number().nullable(),
          }),
          otherwise: () => yup.number().nullable(),
        }),
      otherwise: () => yup.number().nullable(),
    }),

    date: yup.string().when("notApplicable", {
      is: false,
      then: () =>
        yup.string().when("prelaunch", {
          is: false, // or true based on your logic
          // then: () => yup.string().required("AUM month/year is required"),
          then  : () => yup.number().when("not_available", {
            is: false, // or true, based on your logic
            then: () => yup.string().required("AUM month/year is required"),
            otherwise: () => yup.number().nullable(),
          }),
          otherwise: () => yup.string().nullable(),
        }),
      otherwise: () => yup.string().nullable(),
    }),
    foundedDate: yup.date().nullable(),
    // investorType: yup.array().when("type", {
    //   is: (type) => type === 1 || type === 3,
    //   then: () => yup.array().required("Investor Type is required"),
    //   otherwise: () => yup.array().notRequired().nullable(),
    // }),
    profileStatus: yup.boolean().when("type", {
      is: (type) => type === 1 || type === 3,
      then: () => yup.boolean(),
      otherwise: () => yup.boolean().notRequired(),
    }),
  });

  const handleSubmit = async (value) => {
    try {
      dispatch(setLoading(true));
      const res = await editCompanyOverview(value, cookies.cid, cookies.t);
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Company Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="edit-container" id="Company-Edit">
      {CompanyeditActive && (
        <>
          <div className="h-j">Edit Company</div>
          <Formik
            key={Math.random}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
            enableReinitialize
            // onChange={onChange}
          >
            {({
              isValid,
              dirty,
              handleSubmit,
              setFieldValue,
              touched,
              errors,
              setFieldError,
              values,
              handleBlur,
            }) => (
              <>
                {/* <PageHeader
            // title={type === "edit" ? "Edit Company" : "Add Company"}
          /> */}

                <FixedPositionButton
                  onClick={(values) => handleSubmit(values)}
                  disabled={!isValid}
                />

                <div className="edit-page-display mb-4">
                  <div className="left-side">
                    <div className="form-card">
                      <div
                        className="p-0 pt-4"
                        // disabled={company_id && type == "add"}
                      >
                        <h4 className="h3-i semi">Company Details</h4>
                        <hr className="mt-0" />
                        <div className="row g-3">
                          <div className="col-md">
                            <label htmlFor="Logo">Logo</label>
                            <ImageSelector
                              style={ImageSelectorStyle}
                              type="Company"
                              handleFile={(file) => {
                                setFieldValue("logo", file);
                                // saveFormData(
                                //   dirty,
                                //   { ...values, logo: file },
                                //   isValid
                                // );
                              }}
                              onRemove={() =>
                                setFieldValue("remove_profile_photo", true)
                              }
                              aspect={1}
                              widthLimit={400}
                              heightLimit={400}
                              selectedImage={replaceSpacesInImageUrl(
                                maintainedData?.profile_photo
                              )}
                              description="Logo image should be 400X400px and size is 1MB"
                            />
                          </div>
                          <div className="col-md">
                            <label htmlFor="Cover Photo">Cover Photo</label>
                            <ImageSelector
                              style={{
                                ...ImageSelectorStyle,
                                width: "10rem",
                                objectFit: "contain",
                              }}
                              type="Company"
                              handleFile={(file) => {
                                setFieldValue("cover", file);
                              }}
                              aspect={3.5}
                              widthLimit={1400}
                              heightLimit={400}
                              selectedImage={replaceSpacesInImageUrl(
                                maintainedData?.cover_photo
                              )}
                              description="Logo image should be 1400X400px and size is 1MB"
                              onRemove={() =>
                                setFieldValue("remove_cover_photo", true)
                              }
                            />
                          </div>
                        </div>

                        <form action="">
                          <div className="row g-3 mt-3">
                            <InputLayout>
                              <label
                                htmlFor="Company Name"
                                className="required-field"
                              >
                                Company Name
                              </label>
                              <Field
                                type="text"
                                name="name"
                                placeholder="Name"
                                className={`${
                                  touched.name && errors.name
                                    ? "error-input"
                                    : ""
                                }`}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // saveFormData(dirty, values, isValid);
                                }}
                              />
                              {touched.name && errors.name && (
                                <Error error={errors.name} />
                              )}
                            </InputLayout>
                            <InputLayout>
                              <label
                                htmlFor="Company Type"
                                className="required-field"
                              >
                                Company Type
                              </label>
                              <SelectDropdown
                                data={TypeData}
                                onChange={(option) => {
                                  if (option[0] === 2) {
                                    setFieldError("investorType", undefined);
                                  }
                                  setFieldValue(
                                    "type",
                                    option.length > 1 ? 3 : option[0]
                                  );
                                  setFieldValue("type_selected", option);
                                }}
                                multiSelect={true}
                                onClear={() => setFieldValue("type", " ")}
                                placeholder="Select Type"
                                // isClearable={true}
                                selectedValue={
                                  values.type === 1
                                    ? [1]
                                    : values.type === 2
                                    ? [2]
                                    : values.type === 3
                                    ? [1, 2]
                                    : null
                                }
                              />
                            </InputLayout>
                          </div>

                          <div className="row g-3 mt-2">
                            <InputLayout>
                              <label htmlFor="Email">Email</label>
                              <Field
                                type="text"
                                name="email"
                                placeholder="Email"
                                className={`${
                                  touched.email && errors.email
                                    ? "error-input"
                                    : ""
                                }`}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // saveFormData(dirty, values, isValid);
                                }}
                              />
                              {touched.email && errors.email && (
                                <Error error={errors.email} />
                              )}
                            </InputLayout>

                            <InputLayout>
                              <label htmlFor="Website">Website</label>
                              <Field
                                type="text"
                                name="website"
                                placeholder="website"
                                className={`${
                                  touched.website && errors.website
                                    ? "error-input"
                                    : ""
                                }`}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // saveFormData(dirty, values, isValid);
                                }}
                              />
                              {touched.website && errors.website && (
                                <Error error={errors.website} />
                              )}
                            </InputLayout>
                          </div>

                          <div className="row g-3 mt-2">
                            <InputLayout>
                              <label htmlFor="Founded">Founded In</label>
                              <MonthYearSelector
                                placeholder="Month & Year"
                                onChange={(date) => {
                                  setFieldValue(
                                    "foundedDate",
                                    moment(`${date}-10`).toISOString()
                                  );
                                  // saveFormData(
                                  //   dirty,
                                  //   {
                                  //     ...values,
                                  //     foundedDate: moment(`${date}-01`).toISOString(),
                                  //   },
                                  //   isValid
                                  // );
                                }}
                                selectedDate={maintainedData?.founded_date}
                                disableFuture={true}
                              />
                            </InputLayout>

                            <InputLayout>
                              <label htmlFor="Company Phone Number">
                                Company Phone Number
                              </label>
                              <div className="row g-3 w-100">
                                <div className="col-4">
                                  <SelectDropdown
                                    data={countryCodeData}
                                    placeholder="Code"
                                    onChange={(option) => {
                                      setFieldValue("countryCode", option);
                                    }}
                                    isClearable={true}
                                    selectedValue={maintainedData?.country_code}
                                    onClear={(e) => {
                                      setFieldValue("countryCode", " ");
                                      // handleBlur(e);
                                    }}
                                    type="phoneNumber"
                                  />
                                </div>
                                <div
                                  className="col-8 pe-0"
                                  style={{ flex: "auto" }}
                                >
                                  <Field
                                    type="text"
                                    name="contactNumber"
                                    placeholder="Enter Contact Number"
                                    className={`w-100 h-100 ${
                                      touched.contactNumber &&
                                      errors.contactNumber
                                        ? "error-input"
                                        : ""
                                    }`}
                                    onChange={(e) => {
                                      const numericInput =
                                        e.target.value.replace(/[^0-9]/g, "");
                                      setFieldValue(
                                        "contactNumber",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      // saveFormData(dirty, values, isValid);
                                    }}
                                  />
                                </div>
                                {errors.contactNumber && (
                                  <Error error={errors.contactNumber} />
                                )}
                              </div>
                            </InputLayout>
                          </div>

                          <div className="row mt-3">
                            <div className="form-group">
                              {/* <label htmlFor="">Overview</label> */}
                              {/* <Field
                                as="textarea"
                                name="overview"
                                id="overview"
                                // cols="30"
                                // rows="4"
                                placeholder="Enter Overview"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // saveFormData(dirty, values, isValid);
                                }}
                              /> */}
                              <QuillEditor
                                label="Overview"
                                placeholderText="Enter Overview"
                                // onChange={async (content) => {
                                //   await setFieldValue("overview", content);
                                onChange={async (content) => {
                                  await setFieldValue("overview", content);
                                }}
                                hp="80%"
                                height="300px"
                                value={values.overview}
                                // onBlur={() => {
                                //   handleBlur()
                                //   console.log('form-data', values)
                                //   saveFormData(dirty, values, isValid);
                                // }}
                                type=""
                              />
                            </div>
                          </div>

                          <div className="row g-3 mt-2">
                            <InputLayout>
                              <label className="required-field">
                                AUM ($USD Millions)
                              </label>

                              <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: "45%" }}>
                                  <Field
                                    type="number"
                                    name="amount"
                                    placeholder="Enter Amount"
                                    className={`w-100 ${
                                      touched.amount && errors.amount
                                        ? "error-input"
                                        : ""
                                    }`}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      // saveFormData(dirty, values, isValid);
                                    }}
                                  />
                                  {touched.amount && errors.amount && (
                                    <Error error={errors.amount} />
                                  )}
                                </div>

                                <div>as of</div>

                                {/* Second 40% */}
                                <div style={{ width: "40%" }}>
                                  <MonthYearSelector
                                    placeholder="Month & Year"
                                    onChange={(date) => {
                                      setFieldValue(
                                        "date",
                                        moment(`${date}-10`).toISOString()
                                      );
                                      // saveFormData(dirty, values, isValid);
                                    }}
                                    selectedDate={
                                      maintainedData?.aum_month_year
                                    }
                                  />
                                </div>
                              </div>
                            </InputLayout>

                            <div className="col-md">
                              <div
                                className="d-flex w-100"
                                style={{ marginTop: "42px" }}
                              >
                                <div className="d-flex align-items-center w-40 me-3">
                                  <CheckboxWrapper
                                    type="checkbox"
                                    id="notApplicable"
                                    value={values.notApplicable}
                                    checked={values.notApplicable}
                                    onChange={() => {
                                      setFieldValue(
                                        "notApplicable",
                                        !values.notApplicable
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                    }}
                                  />
                                  <label
                                    htmlFor="notApplicable"
                                    className="mb-0 mt-1"
                                  >
                                    Not Applicable
                                  </label>
                                </div>
                                <div className="d-flex align-items-center w-40 me-3">
                                  <CheckboxWrapper
                                    type="checkbox"
                                    id="prelaunch"
                                    value={values.prelaunch}
                                    checked={values.prelaunch}
                                    onChange={() => {
                                      setFieldValue(
                                        "prelaunch",
                                        !values.prelaunch
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                    }}
                                  />
                                  <label
                                    htmlFor="prelaunch"
                                    className="mb-0 mt-1"
                                  >
                                    Pre Launch
                                  </label>
                                </div>
                                <div className="d-flex align-items-center w-40">
                                  <CheckboxWrapper
                                    type="checkbox"
                                    id="not_available"
                                    value={values.not_available}
                                    checked={values.not_available}
                                    onChange={() => {
                                      setFieldValue(
                                        "not_available",
                                        !values.not_available
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                    }}
                                  />
                                  <label
                                    htmlFor="not_available"
                                    className="mb-0 mt-1"
                                  >
                                    Not Available
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* {console.log('checking ',values.type === 1 || values.type === 3 || maintainedData?.company_type_id === 1 ||  maintainedData?.company_type_id === 3)} */}
                    {(values.type === 1 || values.type === 3) && (
                      <div className="form-card mt-3">
                        <div
                          className="pt-3"
                          // disabled={company_id && type == "add"}
                        >
                          <h4 className="h3-i semi">Types</h4>
                          <hr className="mt-0" />

                          <div className="row g-3">
                            <InputLayout>
                              <label htmlFor="Investor Type">
                                Investor Type
                              </label>
                              <SelectDropdown
                                data={investorData}
                                onChange={(option) => {
                                  if (option.length > 0) {
                                    setFieldValue("investorType", option); // Removed 'await'
                                  } else {
                                    setFieldValue("investorType", null); // Removed 'await'
                                  }
                                }}
                                multiSelect={true}
                                isClearable={true}
                                onClear={() =>
                                  setFieldValue("investorType", null)
                                }
                                placeholder="Select Type"
                                selectedValue={maintainedData?.investor_type}
                              />
                              {errors.investorType && (
                                <Error error={errors.investorType} />
                              )}
                            </InputLayout>

                            <InputLayout></InputLayout>
                          </div>
                        </div>
                      </div>
                    )}

                    <TabData companyType={values.type} />
                  </div>

                  {/* <div className="right-side pt-4">
                    <h6 className="h3-i semi">Internal Notes</h6>
                    <hr className="mt-0" />
                    <div
                      className="mt-4"
                      styles={{ padding: "20px", minHeight: "240px" }}
                    >
                      <textarea
                        placeholder="Enter Your Note"
                        onChange={(e) => {
                          setFieldValue("internal_note", e.target.value);
                        }}
                        onPaste={(e) => {
                          setFieldValue("internal_note", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("internal_note", e.target.value);
                        }}
                        className="w-100"
                        value={values.internal_note}
                        rows={8}
                        style={{ minHeight: "255px" }}
                      />
                    </div>

                    {/* <>
                      <h6 className="h3-i semi mt-4">PrimeAlpha Update Date</h6>
                      <hr className="mt-0" />

                      <div className="mt-4">
                        <div className="form-group">
                          <DateSelector
                            placeholder="Select Updation Date"
                            onChange={(date) => {
                              setFieldValue("primealpha_update_date", date);
                            }}
                            selectedDate={
                              maintainedData?.primealpha_update_date
                            }
                          />
                        </div>
                      </div>
                    </> */}
                  {/* )} */}
                  {/* </div> */}
                </div>

                <footer className="footer ">
                  <div className="d-flex gap-3">
                    <button
                      type="submit"
                      className="btn btn-outline-dark px-4 mt-0 "
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary px-4 mt-0 "
                      onClick={(values) => handleSubmit(values)}
                      disabled={!isValid}
                    >
                      Save Changes
                    </button>
                  </div>
                </footer>
              </>
            )}
          </Formik>
        </>
      )}
      {screenWidth <= 900 && <EditBlock />}
    </div>
  );
};

export default EditCompany;
